import { Injectable } from '@angular/core';
import {
  FormTypes,
  IAssessCaseInfo,
  IFormCurrentModelInfo,
  IFormInfoStorage,
  IFormSubmission, IStudentContactInfo
} from '@clients-nside-io/shared/models';
import { DebugUtils, getAssessDataForRoute, IDataForRoute } from '@clients-nside-io/shared/util';
import { isNumeric } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {
  private readonly _StoredFormsDataKey: string = 'storedFormsData';

  constructor(private debug: DebugUtils) { }

  public clear(): void {
    localStorage.clear();
  }

  public setFromRouteData(routeData: IDataForRoute): void {
    this.debug.trace('LocalStoreService - setFromRouteData - routeData:', routeData);

    const assessData = getAssessDataForRoute(routeData);
    if (assessData) {
      if (assessData.isOpeningForm) {
        this.resetForm();
      }

      if (isNumeric(assessData.buildingId)) {
        this.buildingId = assessData.buildingId.toString();
      }

      this.currentFormName = assessData.formName;
      this.assessCaseId = assessData.assessCaseID;
    } else if (routeData?.data?.['isOpeningForm'] == true) {
      this.resetForm();
    }

    this.currentRoute = routeData?.['urlString']?.length > 0
      ? routeData['urlString']
      : routeData?.url?.length > 0
        ? '/' + routeData?.url?.map(m => m.path).join('/')
        : this.currentRoute;
    this.userId = routeData?.data?.['userId'] ?? this.userId;
  }

  public getCurrentFormInfo(): IFormInfoStorage {
    let info: IFormInfoStorage = {
      buildingId: this.buildingId,
      formName: this.currentFormName
    };

    if (this.assessCaseId) {
      info.assessCaseId = this.assessCaseId;
    }

    if (this.assessCaseUID) {
      info.assessCaseUID = this.assessCaseUID;
    }

    return info;
  }

  private _getFormType(formName: string) {
    return formName in FormTypes
      ? FormTypes[formName as keyof typeof FormTypes]
      : FormTypes.default;
  }

  public isValidForForm(): boolean {
    const theFormName: string = this.currentFormName ?? '';
    return theFormName.length > 0
      && this.userId?.length > 0
      && (this.buildingId?.length > 0
        || this._getFormType(theFormName) == FormTypes.anonymous);
  }

  public isValidForAssessForm(): boolean {
    return this.isValidForForm()
      && this.assessCaseId?.length > 0
      && this.assessCaseId.startsWith(this.buildingId);
  }

  public setFromFormSubmission(submission: IFormSubmission): void {
    this.debug.trace('LocalStoreService - setFromFormSubmission - submission:', submission);
    if (!submission) return;

    this.currentFormName = submission.formId?.name;
    this.currentFormData = {
      model: submission.data,
      step: submission.currentStep
    };
  }

  public setFromFormInfoStorage(info: IFormInfoStorage, resetForm: boolean = false): void {
    this.debug.trace('LocalStoreService - setFromFormInfoStorage - info:', info, 'resetForm:', resetForm);
    if (resetForm) {
      this.resetForm(info?.formName);
    } else if (info?.formName) {
      this.currentFormName = info.formName;
    }

    if (isNumeric(info?.buildingId)) {
      this.buildingId = info.buildingId;
    }

    if (info?.assessCaseId || info?.assessCaseUID) {
      this.assessCaseId = info?.assessCaseId ?? '';
      this.assessCaseUID = info?.assessCaseUID ?? '';
    }
  }

  public setFromAssessCase(assessCase: IAssessCaseInfo): void {
    this.debug.trace('LocalStoreService - setFromAssessCase - assessCase:', assessCase);
    if (!assessCase) return;

    this.buildingId = assessCase.BuildingId.toString();
    this.assessCaseId = assessCase.CaseId;
    this.assessCaseUID = assessCase.UID.toString();
  }

  public resetForm(formName?: string): void {
    this.debug.trace('LocalStoreService - resetForm - formName:', formName);
    if (formName && formName?.length > 0) {
      this.currentFormName = formName;
    }

    if (this.isValidForAssessForm()) {
      this.assessCaseUID = '';
      this.assessCaseId = '';
      this.currentFormData = {};
    } else if (this.isValidForForm()) {
      this.currentFormData = {};
    }

    this.currentFormName = formName ?? '';
  }

  get currentRoute(): string {
    return localStorage.getItem('currentRoute') ?? '';
  }

  set currentRoute(value: string) {
    this.debug.trace('LocalStoreService - currentRoute - setting to:', value);
    localStorage.setItem('currentRoute', value);
  }

  get buildingId(): string {
    return localStorage.getItem('buildingId') ?? '';
  }

  set buildingId(value: string) {
    if (isNumeric(value)) {
      localStorage.setItem('buildingId', value);
    }
  }

  get userId(): string {
    return localStorage.getItem('userId') ?? '';
  }

  set userId(value: string) {
    localStorage.setItem('userId', value);
  }

  get assessCaseId(): string {
    return localStorage.getItem('assessCaseId') ?? '';
  }

  set assessCaseId(value: string) {
    localStorage.setItem('assessCaseId', value);
  }

  get assessCaseUID(): string {
    return localStorage.getItem('assessCaseUID') ?? '';
  }

  set assessCaseUID(value: string) {
    localStorage.setItem('assessCaseUID', value);
  }

  get currentFormName(): string {
    return localStorage.getItem('currentFormName') ?? '';
  }

  set currentFormName(value: string) {
    localStorage.setItem('currentFormName', value);
  }

  get currentFormData(): IFormCurrentModelInfo {
    if (!this.isValidForForm()) {
      return {};
    }

    let stored = localStorage.getItem(this._StoredFormsDataKey) ?? '';
    let storedData: IFormsDataStorage;

    try {
      storedData = JSON.parse(stored) as IFormsDataStorage ?? {};
    } catch {
      storedData = {};
    }

    return this._getFormType(this.currentFormName) == FormTypes.anonymous
      ? storedData?.['anonymous']?.[this.userId]?.[this.currentFormName] ?? {}
      : storedData?.[this.buildingId]?.[this.userId]?.[this.currentFormName] ?? {};
  }

  set currentFormData(value: IFormCurrentModelInfo) {
    this.debug.trace('LocalStoreService - currentFormData - setting to:', value);
    if (!this.isValidForForm()) {
      return;
    }

    const stored = localStorage.getItem(this._StoredFormsDataKey) ?? '';
    let storedData: IFormsDataStorage;

    try {
      storedData = JSON.parse(stored) as IFormsDataStorage ?? {};
    } catch {
      storedData = {};
    }

    const isAnonymous = this._getFormType(this.currentFormName) == FormTypes.anonymous;

    if (!isAnonymous && !storedData[this.buildingId]) {
      storedData[this.buildingId] = {};
    }

    if (isAnonymous && !storedData['anonymous']) {
      storedData['anonymous'] = {};
    }

    if (!isAnonymous && !storedData[this.buildingId][this.userId]) {
      storedData[this.buildingId][this.userId] = {};
    }

    if (isAnonymous && !storedData['anonymous'][this.userId]) {
      storedData['anonymous'][this.userId] = {};
    }

    if (isAnonymous) {
      storedData['anonymous'][this.userId][this.currentFormName] = value;
    } else {
      storedData[this.buildingId][this.userId][this.currentFormName] = value;
    }
    localStorage.setItem(this._StoredFormsDataKey, JSON.stringify(storedData));
  }

  get currentStudentInfo(): IStudentContactInfo | null {
    const result = JSON.parse(localStorage.getItem('currentStudentInfo') ?? '[]') as IStudentContactInfo;
    if (result != null) {
      this.currentStudentInfo = null;
    }

    return result;
  }

  set currentStudentInfo(value: IStudentContactInfo | null) {
    localStorage.setItem('currentStudentInfo', JSON.stringify(value));
  }
}

interface IFormsDataStorage {
  [buildingId: string]: IFormsDataUserForms;
}

interface IFormsDataUserForms {
  [userId: string]: IFormsDataFormModels;
}

interface IFormsDataFormModels {
  [formName: string]: IFormCurrentModelInfo;
}
